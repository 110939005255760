import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-14eb9f4e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "relative" }
const _hoisted_2 = ["for"]
const _hoisted_3 = { key: 0 }
const _hoisted_4 = ["id", "name", "value", "rows", "placeholder", "required", "tabindex", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_meta_handler = _resolveComponent("meta-handler")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["relative w-full outline-none text-md transition-colors pl-4 rounded-md cursor-text", [
        `bg-${_ctx.finalTextareaStyle.backgroundColor} text-${_ctx.finalTextareaStyle.inputTextColor}`,
        _ctx.isDisabled
          ? `bg-opacity-70 cursor-default text-opacity-70`
          : `hover:bg-${_ctx.finalTextareaStyle.backgroundHoverColor} focus:bg-${_ctx.finalTextareaStyle.backgroundFocusColor}`,
        _ctx.label ? 'pt-6 pb-1.5' : 'pt-2 pb-2',
        _ctx.$slots.append ? 'pr-10' : 'pr-3',
      ]]),
      onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.textareaElement.focus()))
    }, [
      (_ctx.label)
        ? (_openBlock(), _createElementBlock("label", {
            key: 0,
            for: _ctx.id,
            class: _normalizeClass(["absolute top-1.5 left-0 pl-4 transition-colors font-regular text-sm truncate select-none", `text-${
          !_ctx.hasMeta
            ? _ctx.finalTextareaStyle.labelColor
            : _ctx.inputMeta.errorMessage
            ? _ctx.finalTextareaStyle.errorColor
            : _ctx.inputMeta.saveMessage
            ? _ctx.finalTextareaStyle.saveColor
            : _ctx.inputMeta.infoMessage
            ? _ctx.finalTextareaStyle.infoColor
            : _ctx.finalTextareaStyle.labelColor
        }`])
          }, [
            _createTextVNode(_toDisplayString(_ctx.label) + " ", 1),
            (_ctx.isRequired)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, " *"))
              : _createCommentVNode("", true)
          ], 10, _hoisted_2))
        : _createCommentVNode("", true),
      _createElementVNode("textarea", {
        id: _ctx.id,
        name: _ctx.name,
        value: _ctx.modelValue,
        rows: _ctx.rows,
        placeholder: _ctx.placeholder,
        required: _ctx.isRequired,
        tabindex: _ctx.isDisabled ? -1 : _ctx.tabIndex,
        ref: "textareaElement",
        class: "overflow-x-hidden overflow-ellipsis white-space-nowrap box-border bg-transparent w-full resize-none",
        disabled: _ctx.isDisabled,
        onInput: _cache[0] || (_cache[0] = 
          (event) => {
            _ctx.handleInputEvent(event);
            _ctx.resizeTextarea();
          }
        ),
        autocomplete: false,
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleChangeEvent && _ctx.handleChangeEvent(...args))),
        onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.handleKeyDownEvent && _ctx.handleKeyDownEvent(...args)))
      }, null, 40, _hoisted_4)
    ], 2),
    (_ctx.$slots.append != null)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["absolute right-3 top-3.5 h-6 w-6 flex items-center justify-center overflow-hidden", _ctx.isDisabled ? ' cursor-default opacity-70' : 'cursor-text'])
        }, [
          _renderSlot(_ctx.$slots, "append", {}, undefined, true)
        ], 2))
      : _createCommentVNode("", true),
    (_ctx.hasMeta)
      ? (_openBlock(), _createBlock(_component_meta_handler, {
          key: 1,
          inputMeta: _ctx.inputMeta,
          metaStyle: _ctx.inputStyle
        }, null, 8, ["inputMeta", "metaStyle"]))
      : _createCommentVNode("", true)
  ]))
}